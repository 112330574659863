import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ProductsView from "./ProductsView";
import { Modifiers } from "./Modifiers";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  FormLabel,
  useMediaQuery,
} from "@material-ui/core";
import { Orders } from "./Orders";
import { Categories } from "./Categories";
import ding from "../../sounds/juntos-607.mp3";
import useSound from "use-sound";
import { db, auth } from "../../firebase/config";
import { toast } from "react-toastify";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import CategoryIcon from "@material-ui/icons/Category";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUser } from "../../redux/publicReducer/actions";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import { WebsiteBuilder } from "./WebsiteBuilder";
import { ColorScheme } from "./ColorScheme";
import { AdminChatIcon } from "./AdminChatIcon";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LanguageIcon from "@material-ui/icons/Language";
import PaletteIcon from "@material-ui/icons/Palette";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export function Crud() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [play] = useSound(ding, { volume: 0.75 });
  const orderRef = db.collection("orders").where("restaurantId", "==", user.id);
  const [prods, setProds] = useState([]);
  const [count, setCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Legend = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
        >
          <div
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "green",
              marginRight: "0.5rem",
            }}
          />
          <p>Pickup</p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#0000A0",
              marginRight: "0.5rem",
            }}
          />
          <p>For Delivery</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const unsub = orderRef
      .where("status", "==", "pending")
      .onSnapshot((snap) => {
        let i = count;
        snap.docs.forEach((doc) => {
          console.log(doc.data(), "data");
          i = i + 1;
        });
        setCount(i);
      });

    return unsub;
  }, []);

  useEffect(() => {
    if (count) {
      for (let i = 0; i < count; i++) {
        play();
      }
    }
  }, [count]);

  const adminRouteList = [
    {
      name: "Menu maintenance",
      icon: <FastfoodIcon />,
      route: "/restaurant-menu-maintenance",
    },
    {
      name: "Categories",
      icon: <CategoryIcon />,
      route: "/categories",
    },
    {
      name: "Options",
      icon: <SettingsIcon />,
      route: "/settings",
    },
    {
      name: "New Orders",
      icon: <NotificationsIcon />,
      route: "/new-orders",
    },
    {
      name: "In Process",
      icon: <AutorenewIcon />,
      route: "/in-process",
    },
    {
      name: "Order for pickup",
      icon: <EmojiTransportationIcon />,
      route: "/orders-for-pickup",
    },
    {
      name: "Order for delivery",
      icon: <LocalShippingIcon />,
      route: "/orders-for-delivery",
    },
    {
      name: "Cancelled Orders",
      icon: <CancelIcon />,
      route: "/cancelled-orders",
    },
    {
      name: "Website Builder",
      icon: <LanguageIcon />,
      route: "/website-builder",
    },
    {
      name: "Color Scheme",
      icon: <PaletteIcon />,
      route: "/color-scheme",
    },
  ];

  return (
    <div className={classes.root}>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Menu Maintenance"} <FastfoodIcon />
              </Badge>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Categories"} <CategoryIcon />
              </Badge>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Options"} <SettingsIcon />
              </Badge>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Badge badgeContent={count} color="secondary">
                {!isMobile && "New orders"} <NotificationsIcon />
              </Badge>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "In process"} <AutorenewIcon />
              </Badge>
            }
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Order for pickup"} <EmojiTransportationIcon />
              </Badge>
            }
            {...a11yProps(5)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Order for delivery"} <LocalShippingIcon />
              </Badge>
            }
            {...a11yProps(6)}
          />

          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Completed Orders"} <DoneIcon />
              </Badge>
            }
            {...a11yProps(7)}
          />
          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Cancelled Orders"} <CancelIcon />
              </Badge>
            }
            {...a11yProps(8)}
          />

          <Tab label={"WEBSITE BUILDER"} {...a11yProps(9)} />
          <Tab label={"COLOR SCHEME"} {...a11yProps(10)} />

          <Tab
            label={
              <Badge color="secondary">
                {!isMobile && "Sign out"} <ExitToAppIcon />
              </Badge>
            }
            onClick={() => {
              auth.signOut().then(() => {
                dispatch(setUser(null));
                history.push("/");
              });
            }}
            {...a11yProps(11)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <ProductsView />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Categories />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Modifiers />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Legend />
        <Orders status={"pending"} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Legend />
        <Orders status={"IN_PROGRESS"} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Orders status={"DRIVER_FOR_PICKUP"} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Orders status={"DRIVER_DELIVERING"} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Legend />
        <Orders status={"COMPLETED"} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Legend />
        <Orders status="CANCELLED" />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <WebsiteBuilder />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <ColorScheme />
      </TabPanel>
      <TabPanel value={value} index={11}></TabPanel>
      <AdminChatIcon />
    </div>
  );
}
