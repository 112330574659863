import "../../../App.css";
import Head from 'next/head';
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import LovingAllPeople from "../../../assets/images/loving-all-people-icon.png";
import CreatingSuperheroes from "../../../assets/images/creating-superheroes-icon.png";
import ExplodingTheBox from "../../../assets/images/exploding-the-box-icon.png";
import ImpactingTheWorld from "../../../assets/images/impacting-the-world-icon.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Typography from "@mui/material/Typography";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

export const About = () => {
  useEffect(() => {
    document.title = `About Us: ${restaurantName} is a Kingdom Business.`;
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <Head>
        <meta charSet="utf-8" />
        <link rel="canonical" href="/about" />
        <meta name="description" content="A particular focus is given to low or no 'carb' items, creating preservative-free offerings, made fresh daily, and truly satisfying." />
        <meta name="keywords" content="Purpose, love and serve, excellence, start the day, Deli, Grocery Store, great-tasting food, low-carb, gluten-free, wheat-free, sugar-free, soy-free, business as a mission, Jesus Christ, Family-owned, Discounts, gluten-free friendly, Grab-and-go, Catering Services, Why, Mission, Kingdom Business, Core Values, Loving All People, Impacting the World, Creating Superheroes, Exploding the Box, Keto, St. Charles, Geneva, South Elgin, near me, Ambitious Deli, Keto Retail Shop, preservative-free, fresh daily, satisfying, Holy Spirit, leaders, community, physical and spiritual needs, God's purpose, intentional love, needs of others, impacting, serve, sacrifices, Fox Valley Christian Action, Lazarus House, Hope Coffee, superheroes, capabilities, collaboration, healthy drinks, incredible food items, toxic sugar-free, gluten-free, wheat-free." />
        <meta property="og:title" content={`About Us: ${restaurantName} is a Kingdom Business.`} />
        <meta property="og:description" content="A particular focus is given to low or no 'carb' items, creating preservative-free offerings, made fresh daily, and truly satisfying." />
        <meta property="og:image" content="https://ajsketofactory.com/logo512.png" />
      </Head>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="/about" />
        <meta name="description" content="A particular focus is given to low or no 'carb' items, creating preservative-free offerings, made fresh daily, and truly satisfying." />
        <meta name="keywords" content="Purpose, love and serve, excellence, start the day, Deli, Grocery Store, great-tasting food, low-carb, gluten-free, wheat-free, sugar-free, soy-free, business as a mission, Jesus Christ, Family-owned, Discounts, gluten-free friendly, Grab-and-go, Catering Services, Why, Mission, Kingdom Business, Core Values, Loving All People, Impacting the World, Creating Superheroes, Exploding the Box, Keto, St. Charles, Geneva, South Elgin, near me, Ambitious Deli, Keto Retail Shop, preservative-free, fresh daily, satisfying, Holy Spirit, leaders, community, physical and spiritual needs, God's purpose, intentional love, needs of others, impacting, serve, sacrifices, Fox Valley Christian Action, Lazarus House, Hope Coffee, superheroes, capabilities, collaboration, healthy drinks, incredible food items, toxic sugar-free, gluten-free, wheat-free." />
        <meta property="og:title" content={`About Us: ${restaurantName} is a Kingdom Business.`} />
        <meta property="og:description" content="A particular focus is given to low or no 'carb' items, creating preservative-free offerings, made fresh daily, and truly satisfying." />
        <meta property="og:image" content="https://ajsketofactory.com/logo512.png" />
      </Helmet> */}

      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                Purpose: to love and serve to others through excellence in every
                encounter.{" "}
              </Typography>
              <br />
              <h1 className="gold">
                Great way to
                <br></br>
                start the day
              </h1>
            </Col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                {" "}
                {restaurantName} is an <strong>ambiguous</strong> restaurant venture focused on providing great-tasting food that is <strong>low-carb, gluten-free, wheat-free, sugar-free, and soy-free</strong> while serving the community as a <strong>'business as a mission.'</strong> <em>Sharing the love of Jesus Christ.</em>
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                style={{ backgroundColor: buttonColor }}
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div className="about-info-left" style={{ backgroundColor: colorBackground }}>
              <h2>Highlights from the Business</h2>
              <ul className="paddingSpecial">
                <li>Family-owned & Operated</li>
                <li>Discounts available</li>
                <li>Gluten-free friendly</li>
                <li>Grab-and-go</li>
                <li>Catering Services</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography variant="h4">
                {restaurantName} Why - Our Mission
              </Typography>
              <Typography variant="boby1">
                {" "}
                We are a team who seeks direction from the Holy Spirit, led by
                leaders dedicated to using our time, talent, and money to meet
                the community's physical and spiritual needs to advance God's
                purpose.{" "}
              </Typography>
              <br />

              <Typography variant="h4" className="whitespace">
                About the Business
              </Typography>
              <Typography variant="boby1">
                {restaurantName} is an <strong>ambiguous</strong> restaurant venture focused on providing great-tasting food that is <strong>low-carb, gluten-free, wheat-free, sugar-free, and soy-free</strong> while serving the community as a <strong>'business as a mission.'</strong> <em>Sharing the love of Jesus Christ.</em>
              </Typography>
              <h2 className="whitespace">4 Core Values</h2>
              <div className="about-right-text left">
                <Col xs={4} md={2} className="core-icon">
                  <img src={LovingAllPeople} size="auto" />
                </Col>
                <Col xs={8} md={10} className="paddingRight15">
                  <h2>Loving All People</h2>
                  <h6 align="justify">
                    We define “love” as intentionally placing the needs of
                    others above our own needs, wants, and desires. We recognize
                    the significance of each person and strive to help others
                    see what God sees in them. AJ's Brands believes everyone has an
                    essential role in loving the people in our world; our
                    customers, team members, vendors, and the charities we
                    serve.
                  </h6>
                </Col>
              </div>
              <div className="about-right-text left">
                <Col xs={4} md={2} className="core-icon">
                  <img src={ImpactingTheWorld} size="auto" />
                </Col>
                <Col xs={8} md={10} className="paddingRight15">
                  <h2>Impacting the World</h2>
                  <h6 align="justify">
                    We are called to serve as God commanded, “Do not forget to
                    do good and to share with others, for with such sacrifices
                    God is pleased” (Hebrews 13:16). AJ's Brands understands that we
                    exist to serve our customers; our team members, and the
                    community. Our support for Fox Valley Christian Action and
                    Lazarus House changes lives here in the Fox Valley.
                    Likewise, our support for Hope Coffee provides the resources
                    to save and change lives worldwide.
                  </h6>
                </Col>
              </div>
              <div className="about-right-text left">
                <Col xs={4} md={2} className="core-icon">
                  <img src={CreatingSuperheroes} size="auto" />
                </Col>
                <Col xs={8} md={10} className="paddingRight15">
                  <h2>Creating Superheroes</h2>
                  <h6 align="justify">
                    Behind every great hero is someone (or something) enabling
                    them to be the leader the world needs. God tells us, “Be
                    strong and courageous…for the Lord your God will be with you
                    wherever you go” (Joshua 1:9). We want to be there for you.
                    AJ's Brands empowers our team members with the capabilities and
                    collaboration that turn them into superheroes to the
                    customers they serve. Our impact is defined not just by the
                    products we create and sell but by how we service our
                    customers daily.
                  </h6>
                </Col>
              </div>
              <div className="about-right-text left">
                <Col xs={4} md={2} className="core-icon">
                  <img src={ExplodingTheBox} size="auto" />
                </Col>
                <Col xs={8} md={10} className="paddingRight15">
                  <h2>Exploding the Box</h2>
                  <h6 align="justify">
                    AJ's Brands goes beyond “thinking outside the box.” When we are
                    finished, the box will be unrecognizable. We create healthy,
                    incredible drinks and food items which the market has rarely
                    experienced, free of toxic sugar, gluten, and wheat. God
                    continually surprises us as He is “able to do immeasurably
                    more than all we ask or imagine, according to His power that
                    is at work within us” (Ephesians 3:20).
                  </h6>
                </Col>
              </div>
            </div>
            <div className="inner-about-gallery margin-top-30 ">
              <div className="col-lg-12">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image1`}
                    src={aboutimg1}
                    alt={`${restaurantName} About Us Image1`}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image2`}
                    src={aboutimg2}
                    alt={`${restaurantName} About Us Image2`}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image3`}
                    src={aboutimg3}
                    alt={`${restaurantName} About Us Image3`}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <Col xs={12} className="whitespace">
            <Typography variant="h4" className="dark">
              The Why of {restaurantName} - Purpose
            </Typography>
          </Col>
          <Col xs={10} className="title">
            <Typography variant="boby1">
              {" "}
              AJ Slider's purpose is to love and serve others through excellence
              in every encounter,. Changing lives, one slider at a time!
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video"
              url="https://www.youtube.com/watch?v=f4F9kUTmluY"
            />
          </div>
          <Col xs={12} className "whitespace ">
            <Typography variant="h4" className="dark">
              The Why of {restaurantName} - The Food
            </Typography>
          </Col>
          <Col xs={10} className=" title">
            <Typography variant="boby1">
              {" "}
              Customer focus has been expanded to serve those with celiac
              disease, gluten intolerance, and dairy/lactose intolerance.
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video "
              url="https://www.youtube.com/watch?v=wHIk_-ZVAmw"
            />
          </div>
          <Col xs={12} className="whitespace">
            <Typography variant="h4" className="dark">
              The Why of {restaurantName} - The People
            </Typography>
          </Col>
          <Col xs={10} className=" title">
            <Typography variant="boby1">
              {" "}
              In this video, Lance Bell explains the purpose of the business -
              serving people and impacting the world for Jesus Christ.
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video"
              url="https://www.youtube.com/watch?v=ddpU4TWiGYs"
            />
          </div>*/}
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default withRouter(About);
