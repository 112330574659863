import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const googleMaps = process.env.REACT_APP_GOO_GL_MAPS;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;

export default function DirectionCard() {
  return (
    <Card>
      <a aria-label="Google Map" href={googleMaps} className="none-link" target="_blank" ><CardActionArea className="color-scheme" style={{ backgroundColor: colorBackground }}>
        <CardMedia
          component="img"
          height="140"
          image="/static/get-direction.png"
          width="100%"
          title={`${restaurantName} Get Direction`}
          alt={`${restaurantName} Get Direction`}
          loading="lazy"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Get Direction
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            2075 Prairie Street, Suite 110
            <br></br>
            Saint Charles, IL 60174
          </Typography>
        </CardContent>
      </CardActionArea></a>
    </Card>
  );
}