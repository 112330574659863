import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LanguageIcon from "@material-ui/icons/Language";
import PaletteIcon from "@material-ui/icons/Palette";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import CategoryIcon from "@material-ui/icons/Category";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ProductsView from "../ProductsView";
import { Modifiers } from "../Modifiers";
import { Orders } from "../Orders";
import { Categories } from "../Categories";
import { WebsiteBuilder } from "../WebsiteBuilder";
import { ColorScheme } from "../ColorScheme";
import { AdminChatIcon } from "../AdminChatIcon";
export const adminRouteList = [
  {
    name: "Menu maintenance",
    icon: <FastfoodIcon />,
    route: "/restaurant-menu-maintenance",
    component: <ProductsView />,
  },
  {
    name: "Categories",
    icon: <CategoryIcon />,
    route: "/categories",
    component: <Categories />,
  },
  {
    name: "Options",
    icon: <SettingsIcon />,
    route: "/settings",
    component: <Modifiers />,
  },
  {
    name: "New Orders",
    icon: <NotificationsIcon />,
    route: "/new-orders",
    component: <Orders status="pending" />,
    watchNotification: "pending",
  },
  {
    name: "In Process",
    icon: <AutorenewIcon />,
    route: "/in-process",
    component: <Orders status="IN_PROGRESS" />,
    watchNotification: "IN_PROGRESS",
  },
  {
    name: "Order for pickup",
    icon: <EmojiTransportationIcon />,
    route: "/orders-for-pickup",
    component: <Orders status="DRIVER_PICKING_UP" />,
    watchNotification: "DRIVER_PICKING_UP",
  },
  {
    name: "Order for delivery",
    icon: <LocalShippingIcon />,
    route: "/orders-for-delivery",
    component: <Orders status="DRIVER_DELIVERING" />,
    watchNotification: "DRIVER_DELIVERING",
  },
  {
    name: "Completed Orders",
    icon: <DoneIcon />,
    route: "/orders-history",
    component: <Orders status="COMPLETED" />,
    watchNotification: "COMPLETED",
  },
  {
    name: "Cancelled Orders",
    icon: <CancelIcon />,
    route: "/cancelled-orders",
    component: <Orders status="CANCELLED" />,
    watchNotification: "CANCELLED",
  },
  {
    name: "Website Builder",
    icon: <LanguageIcon />,
    route: "/website-builder",
    component: <WebsiteBuilder />,
  },
  {
    name: "Color Scheme",
    icon: <PaletteIcon />,
    route: "/color-scheme",
    component: <ColorScheme />,
  },
];
