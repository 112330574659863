import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { adminRouteList } from "../constants/route";
import { useHistory } from "react-router-dom";
import { auth } from "../../../firebase/config";
import { db } from "../../../firebase/config";
import { useSelector } from "react-redux";
import Badge from "@material-ui/core/Badge";
import ding from "../../../sounds/juntos-607.mp3";
import useSound from "use-sound";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const AdminDrawer = (props) => {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useSelector((state) => state.public);
  const orderRef = db.collection("orders").where("restaurantId", "==", user.id);
  const [badges, setBadges] = useState({});
  const [count, setCount] = useState(0);
  const [play] = useSound(ding, { volume: 0.75 });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const unsub = orderRef.onSnapshot((snapshot) => {
      let orderNotifications = {};
      snapshot.docs.forEach((doc) => {
        const findExistingStatus = orderNotifications[doc.data().status];

        if (findExistingStatus) {
          orderNotifications[doc.data().status]++;
        } else {
          orderNotifications[doc.data().status] = 1;
        }
      });
      setCount(orderNotifications["pending"] || 0);
      setBadges(orderNotifications);
      console.log(orderNotifications, "notifs");
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (count) {
      for (let i = 0; i < count; i++) {
        play();
      }
    }
  }, [count]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {adminRouteList.map((route) => {
          return (
            <ListItem
              button
              key={route.route}
              onClick={() => {
                history.push(route.route);
              }}
            >
              <ListItemIcon>
                <Badge
                  color="secondary"
                  badgeContent={badges[route.watchNotification] || null}
                >
                  {route.icon}
                </Badge>
              </ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          );
        })}
        <ListItem
          button
          onClick={() => {
            auth.signOut().then(() => {
              history.push("/");
            });
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            AJ Sliders
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

AdminDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
