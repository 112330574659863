import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { db } from "../../firebase/config";
import { setShouldReload } from "../../redux/publicReducer/actions";
import { Checkout } from "../eComm/Checkout";


export const PaymentHandler = () => {
  const [res, setRes] = useState("");
  const { user } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true))
    axios
      .put("https://ajsliders.com/api_j2store/api/verify_payment", {
        data: [
          {
            MerchantID: "45036671410",
            Password: ",3!fjR5aYTt.ExcK",
            PaymentID: localStorage.getItem("pid"),
          },
        ],
      })
      .then((res) => {
        console.log(res.data, "USSSSSSSER");
        setRes(JSON.stringify(res.data, undefined, 2));
        db.collection("users")
          .doc(match.params.userId)
          .collection("cards")
          .doc(res.data.MaskedAccount)
          .set(res.data)
          .then(() => {
            dispatch(setLoading(false))
              window.parent.location.href = `https://ecommv2.servingintel.com/${match.params.restaurantId}/checkout`;
          });
        // db.collection("cards").add({
        //   ...res.data,
        //   user: match.params?.userId,
        // });
        // if (res.data?.Status === "Declined") {
        //   toast.error("Payment Declined");

        //   dispatch(setLoading(false));
        //   dispatch(setShouldReload(true));
        // } else if (res.data?.Status === "Approved") {
        //   const orderData = localStorage.getItem("orderToSend");
        //   db.collection("orders")
        //     .add(JSON.parse(orderData))
        //     .then(() => {
        //       dispatch({ type: "SET_CART", payload: [] });
        //       dispatch(setLoading(false));
        //       toast.success("Thank you for ordering!");
        //       setRes(JSON.stringify(res.data));
        //       dispatch(setShouldReload(true));
        //     });
        // }
      });
  }, []);
  return (
    <div>
      Payment info
      <pre>{res}</pre>
    </div>
  );
};
