import React, { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Drawer,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ProductModal from "./ProductModal";
import BackspaceIcon from "@material-ui/icons/Backspace";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { numberWithCommas } from "../../utils/numbersWithComma";

function ProductsView() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useSelector((state) => state.public);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const productRef = db
    .collection("admins")
    .doc(user.id)
    .collection("products");

  const DeleteDrawer = () => {
    return (
      <Drawer
        anchor="bottom"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <div>
            <Typography>
              Are you sure you want to delete this product?
            </Typography>
          </div>
          <img style={{ maxWidth: "250px" }} src={selectedProduct.image} />
          <div>
            <Typography variant="h5" color="primary">
              {selectedProduct.productName}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(setLoading(true));
              setOpenDelete(false);
              productRef
                .doc(selectedProduct.id)
                .delete()
                .then(() => {
                  dispatch(setLoading(false));
                });
            }}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
    );
  };

  useEffect(() => {
    const unsubscribe = productRef
      .orderBy("productName", "desc")
      .onSnapshot((snapshot) => {
        let _products = [];
        snapshot.docs.forEach((doc) => {
          _products.unshift({ ...doc.data(), id: doc.id });
        });
        console.log(_products, "products");
        setProducts(_products);
      });

    return unsubscribe;
  }, []);

  return (
    <>
      <h1>Menu Maintenance</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
          marginRight: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add product
        </Button>

        <ProductModal
          open={open}
          setOpen={setOpen}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </div>
      {(!isMobile && (
        <Paper className="container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody stripedRows>
              {products.map((product, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      <div
                        style={{
                          maxWidth: isMobile ? "100px" : "200px",
                          width: isMobile ? "100px" : "200px",
                        }}
                      >
                        <img
                          src={product.image}
                          style={{ width: "100%" }}
                          alt={product.name}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{product.productName}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    <TableCell>
                      {Array.isArray(product.category)
                        ? product.category.map((cat) => {
                            return <div>{cat.label}</div>;
                          })
                        : product.category?.label}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedProduct(product);
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedProduct(product);
                            setOpenDelete(true);
                          }}
                        >
                          <BackspaceIcon color="secondary" />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )) ||
        products.map((product) => {
          return (
            <Paper style={{ padding: "1rem", margin: "1rem 0rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "100%" }}>
                  <img
                    src={product.image}
                    style={{ width: "80px" }}
                    alt={product.name}
                  />
                </div>
                <div>
                  <Typography style={{ textAlign: "center" }}>
                    {product.productName}
                  </Typography>
                  <Typography style={{ textAlign: "center" }}>
                    $ {numberWithCommas(product.price)}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    setSelectedProduct(product);
                    setOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSelectedProduct(product);
                    setOpenDelete(true);
                  }}
                >
                  <BackspaceIcon color="secondary" />
                </IconButton>
              </div>

              {product.category.map((cat) => {
                return (
                  <Typography style={{ fontSize: "0.8rem", color: "gray" }}>
                    {cat.label}
                  </Typography>
                );
              })}
            </Paper>
          );
        })}
      <DeleteDrawer />
    </>
  );
}

export default ProductsView;
