// components/user/Text.js
import React, { useState, useEffect } from "react";
import { Slider, FormControl, FormLabel } from "@material-ui/core";
import { useNode } from "@craftjs/core";
import ContentEditable from "react-contenteditable";

export const Text = ({ text, fontSize = 12, textAlign = "left" }) => {
  const {
    connectors: { connect, drag },
    selected,
    dragged,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const [textData, setTextData] = useState(text || "");

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    !selected && setEditable(false);
  }, [selected]);
  return (
    <div ref={(ref) => connect(drag(ref))}>
      <ContentEditable
        html={textData}
        onChange={
          (e) => setTextData(e.target.value)
          // setProp(
          //   (props) => (props.text = e.target.value)
          //   // props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")
          // )
        }
        tagName="p"
        style={{ fontSize: `${fontSize}px`, textAlign }}
      />
      {selected && (
        <FormControl className="text-additional-settings" size="small">
          <FormLabel component="legend">Font size</FormLabel>
          <Slider
            defaultValue={fontSize}
            step={1}
            min={7}
            max={50}
            valueLabelDisplay="auto"
            onChange={(_, value) => {
              setProp((props) => (props.fontSize = value));
            }}
          />
        </FormControl>
      )}
    </div>
  );
};

Text.craft = {
  rules: {
    canDrag: (node) => node.data.props.text != "Drag",
  },
};
