import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  IconButton,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "../../../firebase/config";

export const AdminMessagerList = () => {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const { user } = useSelector((state) => state.public);
  const messageListRef = db
    .collection("admins")
    .doc(user.id)
    .collection("messaging");

  useEffect(() => {
    const unsub = messageListRef.onSnapshot((snapshot) => {
      setCustomers(
        snapshot.docs.map((doc) => {
          console.log(doc.data(), doc.id);
          return { ...doc.data(), id: doc.id };
        })
      );
    });

    return unsub;
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => history.push("/admin")}
          style={{ marginRight: "1rem" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Messaging</Typography>
      </div>
      <Divider />
      <List>
        {customers.map((customer) => {
          return (
            <ListItem
              button
              onClick={() => history.push(`/chat/${customer.id}`)}
            >
              <ListItemText
                primary={customer.name}
                secondary={customer.email}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
