import React, { useEffect, useState } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { AddIcon } from "@material-ui/icons/Add";
import { ModifierModal } from "./ModifierModal";
import { db } from "../../firebase/config";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CollectionModal } from "./CollectionModal";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { ModifierCollection } from "./ModifierCollection";
import { useSelector } from "react-redux";

export const Modifiers = () => {
  const [openAddMods, setOpenAddMods] = useState(false);
  const { user } = useSelector((state) => state.public);
  const [productOptions, setProductOptions] = useState([]);
  const [openCol, setOpenCol] = useState(false);
  const [modId, setModId] = useState("");
  const [parentId, setParentId] = useState("");
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [allModifiers, setAllModifiers] = useState([]);
  const productOptionsRef = db
    .collection("admins")
    .doc(user.id)
    .collection("productOptions");
  const history = useHistory();

  useEffect(() => {
    const unsub = productOptionsRef.onSnapshot((snap) => {
      const _productOptionsRef = snap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      console.log(_productOptionsRef, "options");

      setProductOptions(_productOptionsRef);
    });

    return unsub;
  }, []);

  return (
    <React.Fragment>
      <Route exact path="/settings">
        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={AddIcon}
            onClick={() => {
              setOpenAddMods(true);
            }}
          >
            Add modifier
          </Button>
          <ModifierModal
            open={openAddMods}
            setOpen={setOpenAddMods}
            setSelectedEdit={setSelectedEdit}
            selectedEdit={selectedEdit}
          />
          {modId && (
            <CollectionModal
              open={openCol}
              setOpen={setOpenCol}
              id={modId}
              setId={setModId}
            />
          )}
          <div style={{ marginTop: "1rem" }} />
          {productOptions.length > 0 &&
            productOptions.map((option, index) => {
              return (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{option.optionName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>Type : {option.label}</AccordionDetails>
                  <AccordionDetails>
                    <div style={{ marginRight: "1rem" }}>
                      {option.type !== "TEXTAREA" && option.type !== "TEXT" && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            history.push(`/settings/mod/${option.id}`);
                          }}
                        >
                          Configure Modifier
                        </Button>
                      )}
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setSelectedEdit(option);
                        setOpenAddMods(true);
                      }}
                    >
                      Edit Modifier
                    </Button>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      </Route>

      <Route path="/settings/mod/:modifierId">
        <ModifierCollection />
      </Route>
    </React.Fragment>
  );
};
