import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const phoneNumber = process.env.REACT_APP_CONTACT_US_PHONE;
const emailAddress = process.env.REACT_APP_CONTACT_US_EMAIL;

export default function PhoneCard() {
  return (
    <Card>
      <a aria-label={`Call Phone Number ${phoneNumber}`} href={`tel:${phoneNumber}`} className="none-link" target="_blank" ><CardActionArea className="color-scheme" style={{ backgroundColor: colorBackground }} >
        <CardMedia
          component="img"
          height="140"
          image="/static/callusnow.png"
          width="100%"
          title={`${restaurantName} Contact Us Now`}
          alt={`${restaurantName} Contact Us Now`}
          loading="lazy"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Contact Us Now
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            Phone: {phoneNumber}
            <br></br>
            Email: {emailAddress}
          </Typography>
        </CardContent>
      </CardActionArea></a>
    </Card>
  );
}