import React, { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import {
  Paper,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Button,
} from "@material-ui/core";
import { numberWithCommas } from "../../utils/numbersWithComma";
import { useHistory } from "react-router-dom";
export const HomeDriver = () => {
  const [orders, setOrders] = useState([]);
  const orderRef = db.collection("orders");
  const history = useHistory();

  useEffect(() => {
    const unsub = orderRef
      .where("status", "==", "IN_PROGRESS")
      .where("delivery", "==", true)
      .onSnapshot((snapshot) => {
        setOrders(
          snapshot.docs.map((doc) => {
            console.log(doc.data());
            return {
              ...doc.data(),
              id: doc.id,
            };
          })
        );
      });

    return unsub;
  }, []);

  return (
    <React.Fragment>
      <Container>
        {orders.map((order) => {
          return (
            <Paper
              key={order.id}
              style={{ padding: "1rem", margin: "1rem 0rem" }}
              onClick={() => {}}
            >
              <Typography>{order.name}</Typography>
              <Divider style={{ margin: "1rem 0rem" }} />
              <Stepper>
                <Step>
                  <StepLabel>
                    {order.currentRestaurant.restaurantName}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>{order.address}</StepLabel>
                </Step>
              </Stepper>

              <Typography>${numberWithCommas(order.totalWithTax)}</Typography>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.href="/home"
                  }}
                >
                  Pickup order
                </Button>
              </div>
            </Paper>
          );
        })}
      </Container>
    </React.Fragment>
  );
};
