import React from "react";
import { IconButton } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import { useHistory } from "react-router-dom";

export const AdminChatIcon = () => {
  const history = useHistory();

  return (
    <div style={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
      <IconButton
        onClick={() => {
          history.push("/admin-messaging-list");
        }}
      >
        <ChatIcon color="secondary" fontSize="large" />
      </IconButton>
    </div>
  );
};
