import React, { useState, useEffect } from "react";
import { NavBar } from "./navigation/NavBar";
import parse from "html-react-parser";
import { useRouteMatch } from "react-router-dom";
import { db } from "../../firebase/config";
import { Container, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

export const DynamicContent = () => {
  const [html, setHtml] = useState("");
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const unsub = db
      .collection("contentsManagement")
      .doc(match.params.contentId)
      .onSnapshot((snapshot) => {
        setHtml(snapshot.data().html);
        dispatch(setLoading(false));
      });

    return unsub;
  }, [match.params.contentId]);
  return (
    <>
      <NavBar />
      <Container>
        <Typography
          variant="h3"
          style={{ fontWeight: "800", margin: "1rem 0rem" }}
        >
          {match.params.page}
        </Typography>
        {parse(html)}
      </Container>
    </>
  );
};
