import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantAddress = process.env.REACT_APP_RESTAURANT_ADDRESS;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={7}>
              <Typography className="white">
                {" "}
                Service Options: Dine-in | Curbside pickup | No-contact delivery
              </Typography>
              <h1 className="left gold blankspace">
                Sugar Free, Gluten-Free,
                <br></br>
                Wheat Free, Soy Free
              </h1>

              <Typography className="white blankspace">
                {" "}
                Spread the word and help your neighbors get to know {restaurantName}, today!
              </Typography>
              <Typography className="white blankspace">
                {" "}
                {restaurantAddress}
              </Typography>
              <Button
                className="btn-red whitespace margin-bottom-10"
                href="/register"
                style={{ backgroundColor: buttonColor }}
              >
                Join our AJ's Club Program{" "}
              </Button>
            </Col>
            <Col xs={12} lg={5} className="PhonerightImg"></Col>

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};