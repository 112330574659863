import React, { useState, useEffect, useRef } from "react";
import { Form, Input } from "../../components";
import { db } from "../../firebase/config";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  IconButton,
  Popover,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SendIcon from "@material-ui/icons/Send";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import moment from "moment";
import ModeCommentIcon from "@material-ui/icons/ModeComment";

export const CustomerChat = ({ restaurant }) => {
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState(0);
  const match = useRouteMatch();
  const [hasLoaded, setHasLoaded] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const adminRef = db
    .collection("admins")
    .doc(match.params.restaurantId)
    .collection("messaging")
    .doc(user?.id);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const lastChat = useRef();

  const handleClick = (event) => {
    if (!user) {
      return history.push("/sign-in");
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string().required("Type a message"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = methods;

  const sendMessage = (data) => {
    console.log(data, "data");
    adminRef.set(user).then(() => {
      adminRef.collection("messages").add({
        ...data,
        createdAt: new Date(),
        id: user.id,
      });
      reset(initialValues);
      lastChat.current.scrollIntoView({ behavior: "smooth" });
    });
  };

  useEffect(() => {
    const unsub = adminRef
      .collection("messages")
      .orderBy("createdAt", "asc")
      .onSnapshot((snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => {
            console.log(doc.data());
            return { ...doc.data() };
          })
        );
        setCount(snapshot.docs.length);
        setHasLoaded(true);
      });

    return unsub;
  }, [user, open]);

  useEffect(() => {
    if (lastChat.current && hasLoaded && count) {
      console.log("logging");
      lastChat.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lastChat?.current, hasLoaded, count]);

  return (
    <div>
      <ModeCommentIcon onClick={handleClick} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "0px",
            height: "100vh",
            width: "100vw",
            left: "0px",
            backgroundColor: "white",
            display: "block",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "1rem",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon color="secondary" fontSize="large" />
            </IconButton>

            <Typography>{restaurant}</Typography>
          </div>

          <Divider />
          {hasLoaded && (
            <div
              style={{ padding: "1rem", maxHeight: "80vh", overflowY: "auto" }}
            >
              {messages.map((message, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent:
                        message.id === user.id ? "flex-end" : "flex-start",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      style={{
                        padding: "1rem",
                        borderRadius: "25px",
                        backgroundColor:
                          message.id === user.id ? "#87CEEB" : "#f5f5f5",
                      }}
                    >
                      <Typography>{message.message}</Typography>
                    </div>
                  </div>
                );
              })}
              <div ref={lastChat} />
            </div>
          )}

          <div
            style={{
              padding: "0rem 1rem",
              marginTop: "auto",
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <Form onSubmit={sendMessage} provider={methods}>
              <Input
                name="message"
                variant={"outlined"}
                label="Send a message"
                endAdornment={
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                }
              />
            </Form>
          </div>
        </div>
      </Popover>
    </div>
  );
};
