import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from "@material-ui/core";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { db } from "../../firebase/config";
import { useSelector } from "react-redux";

export const CollectionModal = ({ open, setOpen, id, setId }) => {
  const { user } = useSelector((state) => state.public);
  const [collections, setCollections] = useState([]);
  const productOptionsValueRef = db
    .collection("admins")
    .doc(user.id)
    .collection("productOptions")
    .doc(id);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Collection name is required"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset } = methods;

  useEffect(() => {}, []);

  const handleSubmit = (data) => {
    console.log(data, "submit");

    productOptionsValueRef.update({ modifiers: [] }).then(() => {
      reset();
      setOpen(false);
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => {
        setId("");
        setOpen(false);
      }}
    >
      <Form onSubmit={handleSubmit} provider={methods}>
        <DialogTitle>Add Collection </DialogTitle>
        <DialogContent>
          <Input name="name" placeholder="Collection name" />
          <Typography style={{ fontSize: " 1.3rem" }}>Collections</Typography>
          {collections.map((collection) => {
            return (
              <div>
                <Typography>{collection.name}</Typography>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" type="submit">
            Save to Collection
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
