import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Cart } from "../cart";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { useHistory } from "react-router-dom";

const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "0 1rem",
  marginLeft: "auto",
  marginRight: "auto",
};

const buttonStyle = {
  flex: 1,
  height: "5.5rem",
  width: "100%",
};

const buttonWithGapStyle = {
  ...buttonStyle,
  marginRight: ".5rem",
};

export default function BottomAppBar() {
  const history = useHistory();
  return (
    <React.Fragment>
      {enableKiosk === "1" ? (
        <AppBar
          position="fixed"
          sx={{
            background: "transparent",
            top: "auto",
            bottom: 0,
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Box sx={containerStyle}>
              <Button
                variant="contained"
                style={{
                  ...buttonWithGapStyle,
                  backgroundColor: "#A20F1C",
                  fontSize: "1.5rem",
                  width: "500px",
                }}
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Cancel Order
                <CancelOutlined
                  fontSize="large"
                  style={{
                    transform: "scale(1)",
                    width: "2em",
                    height: "2em",
                    padding: "9px",
                    marginLeft: "1.25rem",
                    backgroundColor: colorBackground,
                    color: "black",
                    borderRadius: "1em",
                  }}
                />
              </Button>

              <Button
                color="success"
                variant="contained"
                style={{
                  ...buttonStyle,
                  backgroundColor: "#3D5309",
                  fontSize: "1.5rem",
                  width: "500px",
                }}
                onClick={() => {
                  history.push("/checkout");
                }}
              >
                Proceed to Checkout
                <Cart />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}
