import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;

export default function BottomLanding() {
  return (
    <a href="/menu">
      <AppBar position="fixed" sx={{ background: "#bbbac5", top: 'auto', bottom: 0 }}>
        <div className="bottomlanding padding-top-20">
          <div className="footer-top d-md-block">
            <div className="container">
              <div className="row align-items-center justify-content-between padding-bottom-25">
                <a href="/menu" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: "black" }}>
                  <div className="col-lg-6 col-md-6">
                    <div className="f-logo">
                      <img className="App-logo" src={Logo} alt={`${restaurantName} logo`} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="f-title center">
                      <h1 style={{ marginLeft: '20px' }}>
                        Tap anywhere to<span> start</span>
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </AppBar>
    </a>
  );
}
