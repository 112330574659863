import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Logo from "../../../assets/images/logo.png";
import ordernowimgr from "../../../assets/images/ordernow-imgr.svg";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  h5,
  Button,
} from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";
import "../../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";
import Toolbar from "@mui/material/Toolbar";
import { LowerNav } from "../navigation/NavBar";
import StickyCategoriesNav from "../components/StickyCategoriesNav";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

export const MenuNavigation = () => {
  const history = useHistory();
  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_name",
    "sic_user_id",
  ]);

  const query = enableKiosk === "0" ? "(max-width: 600px)" : "(max-width: 0px)";
  const isMobile = useMediaQuery(query);

  return (
    <>
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        className={enableKiosk === "0" ? "sticky-sm-top navBarKeto" : "HeaderImgeKiosk sticky-sm-top"}
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/home">
          <img
            width="100%"
            height="100%"
            title={`${restaurantName} logo`}
            className="App-logo"
            src={Logo}
            alt={`${restaurantName} logo`}
            loading="lazy"
          />
        </Navbar.Brand>

        {enableKiosk === "0" ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {
                <Nav.Link
                  href="#"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  About Us
                </Nav.Link>
              }
              {/* <Nav.Link
              href="#"
              onClick={() => {
                history.push("/menu");
              }}
            >
              Order Now
            </Nav.Link> */}
              {/* <Nav.Link
              href="#"
              onClick={() => {
                history.push("/team");
              }}
            >
              Team
            </Nav.Link> */}
              <Nav.Link
                href="#"
                onClick={() => {
                  history.push("/contact");
                }}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  history.push(
                    cookieSIC.sic_user_id > 0 ? "/rewards" : "/register"
                  );
                }}
              >
                {cookieSIC.sic_user_id > 0 ? "eLoyalty" : "Join AJ's Club"}
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => {
                  history.push("/photo-gallery");
                }}
              >
                Photo Gallery
              </Nav.Link>
              <Nav.Link
                className="red"
                style={{ color: "white", backgroundColor: buttonColor, }}
                href="#"
                onClick={() => {
                  history.push("/");
                }}
              >
                Order Now
              </Nav.Link>
              {/*<Nav.Link href="#">Gift Cards</Nav.Link>
            <Nav.Link href="#">Team</Nav.Link>
            <Nav.Link href="#">Join Our Loyalty Program</Nav.Link>*/}
            </Nav>
            <span className="white topbtn">
              {!isMobile
                ? cookieSIC.sic_firstName
                  ? parseHtml(
                    "Welcome back <b>" + cookieSIC.sic_firstName + "!</b>"
                  )
                  : null
                : null}
            </span>
            {/* <a href="/menu" className="topbtn">
            <img src={ordernowimgr} />
          </a> */}
          </Navbar.Collapse>
        ) : (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                mx: "auto",
                marginTop: 0,
                // paddingRight: "70px"
              }}
            >
              <h3 style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "1.75rem" }}>
                {tagline} <b className="black"></b>
              </h3>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                marginTop: 0,
              }}
            >
              <LowerNav />
            </Box>
          </Toolbar>
        )}
      </Navbar>
    </>
  );
};
