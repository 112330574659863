import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase/config";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OrderListing } from "./OrderListing";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { SampleReceipt } from "./SampleReceipt";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

const Legend = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
      >
        <div
          style={{
            height: "1rem",
            width: "1rem",
            backgroundColor: "green",
            marginRight: "0.5rem",
          }}
        />
        <p>Pickup</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: "1rem",
            width: "1rem",
            backgroundColor: "#0000A0",
            marginRight: "0.5rem",
          }}
        />
        <p>For Delivery</p>
      </div>
    </div>
  );
};
export const Orders = ({ status = "" }) => {
  const { user } = useSelector((state) => state.public);
  const [orders, setOrders] = useState([]);
  const orderRef = db.collection("orders").where("restaurantId", "==", user.id);
  const usersRef = db.collection("users");
  const componentRef = useRef();
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const unsub = orderRef
      .orderBy("orderDate", "desc")
      .where("status", "==", status)
      .onSnapshot((snap) => {
        let items = [];
        snap.docs.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setOrders(items);
      });

    return unsub;
  }, [status]);

  return (
    <div>
      <Legend />
      {orders.map((order, index) => {
        console.log(order.futureDelivery);
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{ color: order.delivery ? "#0000A0" : "green" }}
              >
                {order.name}{" "}
                {order.futureDelivery
                  ? `(Future Delivery) ${moment(
                      order?.futureDelivery?.deliveryDate
                    ).format("MMMM DD, YYYY")}`
                  : null}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div>
                  {order.delivery && (
                    <>
                      <Typography>To be delivered at</Typography>
                      <Typography>Address : {order.address}</Typography>
                    </>
                  )}
                </div>
                <Typography>
                  Order sent @{" "}
                  {moment(order.orderDate).format("MMMM DD, YYYY h:mm a")}
                </Typography>
                <div style={{ marginTop: "1rem" }}>
                  {order.status === "pending" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dispatch(setLoading(true));
                        db.collection("orders")
                          .doc(order.id)
                          .update({ status: "IN_PROGRESS" })
                          .then(() => {
                            toast.success(
                              "Order has transitioned to in process"
                            );
                            dispatch(setLoading(false));
                          });
                        // usersRef
                        //   .doc(order.user)
                        //   .collection("orderHistory")
                        //   .doc(order.id)
                        //   .update({ status: "IN_PROGRESS" });
                        // orderRef
                        //   .doc(order.id)
                        //   .set({ ...order, status: "IN_PROGRESS" })
                        //   .then(() => {
                        //     dispatch(setLoading(false));
                        //     toast.success(
                        //       "Order has transitioned to in process"
                        //     );
                        //   });
                      }}
                    >
                      Process order
                    </Button>
                  )}
                  {order.status === "IN_PROGRESS" && !order.delivery && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dispatch(setLoading(true));
                        db.collection("orders")
                          .doc(order.id)
                          .update({ status: "COMPLETED" })
                          .then(() => {
                            dispatch(setLoading(false));
                          });
                        // usersRef
                        //   .doc(order.user)
                        //   .collection("orderHistory")
                        //   .doc(order.id)
                        //   .update({ status: "COMPLETED" });
                        // orderRef
                        //   .doc(order.id)
                        //   .set({ ...order, status: "COMPLETED" })
                        //   .then(() => {
                        //     toast.success("Order has been completed");
                        //   });
                      }}
                    >
                      Complete Order
                    </Button>
                  )}

                  {order.status === "DRIVER_PICKING_UP" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dispatch(setLoading(true));
                        db.collection("orders")
                          .doc(order.id)
                          .update({ status: "DRIVER_DELIVERING" })
                          .then(() => {
                            dispatch(setLoading(false));
                          });
                      }}
                    >
                      Dispatch
                    </Button>
                  )}
                </div>
                {/* <div style={{ marginTop: "1rem" }}>
                  <Button
                    variant="contained"
                    colro="primary"
                    onClick={handlePrint}
                  >
                    Print Order
                  </Button>
                </div> */}

                <OrderListing orders={order.orders} />
                {/* <div ref={componentRef}>
                  <SampleReceipt />
                </div> */}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
