import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter, useHistory, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountForm from "./components/AccountForm";
import MobileForm from "./components/MobileForm";
import PersonalDetails from "./components/PersonalDetails";
import siLogo from "../../../assets/images/servingintel-logo-bw.svg";
import CookieIcon from "../../../assets/images/cookie-icon.svg";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;

export const ChangePassword = () => {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siLogo}
            alt="ServingIntel Logo"
            width="100%"
            height="100%"
            title="ServingIntel Logo"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  const history = useHistory();
  const theme = createTheme();
  const [cPassword, setcPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [newPasswordConfirm, setnewPasswordConfirm] = useState("");

  // const [data, setData] = useState([]);
  const { id } = useParams();

  const [values, setValues] = useState({
    cPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  })

  useEffect(() => {
    axios.get("https://ecommv2.servingintel.com/customer/" + id)
    .then(res => {
      setValues(res.data)
    })
    .catch(err => console.log(err))
  }, [])

 

  // const handleUpdate = (e) => {
  //   e.preventDefault();
  
  //   // Check if the value of the textfield matches the currentPassword from axios
  //   if (values.cPassword !== currentPassword) {
  //     console.log("Current password is incorrect");
  //     return;
  //   }
  
  //   // Check if the newPassword and newPasswordConfirm are the same
  //   if (values.newPassword !== values.newPasswordConfirm) {
  //     console.log("New password and confirm password do not match");
  //     return;
  //   }
  
  //   // Update the currentPassword
  //   axios.put("https://ecommv2.servingintel.com/customer/" + id, values)
  //     .then(res => {
  //       console.log("Password updated successfully");
  //       // Add any further actions after password update here
  //     })
  //     .catch(err => {
  //       console.log("Error updating password:", err);
  //       // Handle error cases here
  //     });
  // }
  // once confirmed
  // onChange={e=>setValues({...values, newPassword: e.target.value})}

//   const submit = () => {
//     const url =
//       "https://ecommv2.servingintel.com/customer/register-eloyalty/" +
//       serverId +
//       "/" +
//       siteId +
//       "/" +
//       storeId;
//     axios
//       .post(url, {
//         firstName: firstName,
//         lastName: lastName,
//         email: email,
//         mobile: mobile,
//         password: password,
//         DOB: DOB,
//         anniv: anniv,
//       })
//       .then((response) => {
//         if (response.data > 0) {
//           handleNext();
//         } else {
//           if (response.data == -1) {
//             Swal.fire({
//               title: "Registration Failed!",
//               text: "This email is already registered. Please use another email.",
//               icon: "info",
//               confirmButtonText: "OK",
//             });
//           } else {
//             Swal.fire({
//               title: "Registration Failed!",
//               text: "Fields marked with * are required",
//               icon: "warning",
//               confirmButtonText: "OK",
//             });
//           }
//         }
//       })
//       .catch((error) => {});
//   };

  useEffect(() => {
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Change Password</title>
            <link rel="canonical" href="/change-password" />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <a href="/" target="_self"> 
            <img
              className="Logo title"
              style={{ padding: "10px 0", width: "150px" }}
              src={Logo}
              width="100%"
              height="100%"
              title="AJ's Keto Factory Logo"
              alt="AJ's Keto Factory Logo"
              loading="lazy"
            />
            </a>
            <Typography
              component="h1"
              className="white"
              variant="h4"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Change password
            </Typography>
            <div className="col-lg-12">
            <div className="white">
              <h3>Enter a new password below to change your password</h3>
            </div>
            </div>
           
            <Paper
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              
              <React.Fragment>
                <Container className="formBG" style={{ padding: "0" }}>
                    <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    variant="outlined"
                    padding="0"
                    >
                    <TextField
                        defaultValue=""
                        margin="dense"
                        required
                        fullWidth
                        id="cPassword"
                        label="Current password"
                        name="cPassword"
                        autoComplete="true"
                        onChange={(e) => {
                          setnewPassword(e.target.value);
                        }}
                    />
                    <TextField
                        defaultValue=""
                        margin="dense"
                        required
                        fullWidth
                        id="newPassword"
                        label="New password"
                        name="newPassword"
                        autoComplete="true"
                        onChange={(e) => {
                          setnewPassword(e.target.value);
                        }}
                    />
                    <TextField
                        defaultValue=""
                        margin="dense"
                        required
                        fullWidth
                        id="newPasswordConfirm"
                        label="Re-type new password"
                        name="newPasswordConfirm"
                        autoComplete="true"
                        onChange={(e) => {
                          setnewPasswordConfirm(e.target.value);
                        }}
                    />
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                        >
                            Submit new password
                        </Button>
                        <Button
                        fullWidth
                        onClick={() => {
                            history.push("/");
                        }}
                        size="large"
                        variant="contained"
                        style={{
                          fontWeight: "550",
                          backgroundColor: "#8c959f",
                          borderColor: "none",
                          margin: ".5rem 0",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                </Container>
                </React.Fragment>
            </Paper>
          </Box>
          <Copyright />
        </Container>
        <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-red"
        style={{ background: "#000" }}
        buttonStyle={{ fontSize: "16px" }}
        expires={365}
      >
        <div className="center">
          <Col xs={3} className="padding-bottom-20 center">
            <img
              width="100%"
              height="100%"
              title="Accept all cookies Icon"
              src={CookieIcon}
              size="15"
              alt="Accept all cookies Icon"
              loading="lazy"
            />
          </Col>
          <p>
            <h3 className="center"> Your Privacy </h3>
          </p>
        </div>

        <Typography>
          Greetings! Our website uses cookies so we may better serve you. By
          clicking “Accept all cookies” and by continuing to browse our site you
          are agreeing to our{" "}
        </Typography>
        <Typography>
          <a
            href="#"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms and Conditions
          </a>
        </Typography>
      </CookieConsent>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(ChangePassword);
