import React, { useState, useRef, useEffect } from "react";
import ateDolly from "../../assets/ateDolly.png";
import { Button, Typography, IconButton, Grid, Paper } from "@material-ui/core";
import { db } from "../../firebase/config";
import { useRouteMatch, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "./map.scss";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  "pk.eyJ1IjoibXl3YWl0YWRhdmlkIiwiYSI6ImNrbW43ZWR4ZzAzeDAycG16ZGR6dTI0MWsifQ.r2NWKe9-sPkaKaEqwK0LEA";
export const StatusScreen = () => {
  const [orderStatus, setOrderStatus] = useState({});
  const mapContainer = useRef();

  const history = useHistory();
  const match = useRouteMatch();
  const orderStatusRef = db.collection("orders").doc(match.params.orderId);

  const [lat, setLat] = useState(-33.7774812);
  const [lng, setLng] = useState(151.1802159);
  const [zoom, setZoom] = useState(12);

  // {lat: -, lng: }

  const staticStatus = [
    "pending",
    "IN_PROGRESS",
    "DRIVER_PICKING_UP",
    "DRIVER_ON_THE_WAY",
    "COMPLETED",
  ];

  useEffect(() => {
    const unsub = orderStatusRef.onSnapshot((doc) => {
      console.log(doc.data(), "doc");
      setOrderStatus(doc.data());
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (
      orderStatus.status === staticStatus[2] ||
      orderStatus.status === staticStatus[3]
    ) {
      const map = new mapboxgl.Map({
        container: mapContainer.current || "",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: zoom,
      });
      const directions = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
      });
      const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);

      map.addControl(new mapboxgl.NavigationControl(), "top-right");

      return () => map.remove();
    }
  }, [orderStatus]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0rem 1rem",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 500, fontSize: "2.2rem" }}>
          {moment().add(30, "minutes").format("h:mm")}
        </Typography>
        <Typography variant="caption" style={{ color: "gray" }}>
          Estimated arrival
        </Typography>
      </div>

      <Grid container justify="center" style={{ margin: "1rem 0rem" }}>
        {staticStatus.map((status, index) => {
          return (
            <Grid item xs={2} style={{ margin: "0rem 6px" }}>
              <div
                style={{
                  height: "3px",
                  backgroundColor:
                    orderStatus.status === status ||
                    staticStatus.indexOf(orderStatus.status) > index
                      ? "#03C04A"
                      : "#cecece",
                  width: "100%",
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <div style={{ padding: "0rem 1rem", marginBottom: "1rem" }}>
        <Typography style={{ fontWeight: 800 }} variant="subtitle2">
          {orderStatus.status === "pending"
            ? "Your order is on queue, Please wait..."
            : orderStatus.status === "IN_PROGRESS"
            ? "Your order is in process"
            : orderStatus.status === staticStatus[2]
            ? "Dolly is picking up your order"
            : "Order Completed!"}
        </Typography>
        <Typography variant="caption">{`Latest arrival by ${moment()
          .add(40, "minutes")
          .format("h:mm a")}`}</Typography>
      </div>
      {(orderStatus?.status !== staticStatus[2] &&
        orderStatus.status !== staticStatus[3] && (
          <div
            style={{
              // height: "100vh",
              // width: "100vw",
              width: "100%",
              height: "50vh",
              backgroundImage:
                orderStatus.status === "pending"
                  ? "url(https://i.pinimg.com/originals/77/bd/95/77bd9523d3332fd28e3e0baf341a95b8.gif)"
                  : orderStatus.status === "IN_PROGRESS"
                  ? "url(https://i.pinimg.com/originals/72/9b/17/729b174fb8d50fce2c76e2dcc4aa14e4.gif)"
                  : "url(https://estheticworldbeauty.com/wp-content/uploads/2017/09/ok-ikonica.gif)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundPosition: "center",
              flexDirection: "column",
            }}
          >
            {/* <div style={{ position: "fixed", top: "20px", right: "20px" }}>
          <IconButton
            onClick={() => {
              history.push("/home");
            }}
          >
            <HomeIcon fontSize="large" style={{ color: "black" }} />
          </IconButton>
        </div> */}

            {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            history.push("/home");
          }}
        >
          Back To Home
        </Button> */}
          </div>
        )) || (
        <div ref={mapContainer} style={{ height: "40vh", width: "100vw" }} />
      )}
      {(orderStatus.status === staticStatus[2] ||
        orderStatus.status === staticStatus[3]) && (
        <Paper style={{ padding: "1rem", margin: "1rem 0rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="caption">
              Dolly is in a Ford Everest
            </Typography>
            <img
              src={ateDolly}
              style={{ borderRadius: "50%", width: "50px" }}
              alt="Ate dolly"
            />
          </div>
        </Paper>
      )}
      <div style={{ marginTop: "1rem", padding: "0rem 1rem" }}>
        <Typography style={{ fontWeight: 800 }} variant="subtitle2">
          Delivery Details
        </Typography>
        <Typography style={{ margin: "1rem 0rem" }} variant="subtitle2">
          {orderStatus.address} <LocationOnIcon />
        </Typography>
        <Typography variant="caption" style={{ color: "gray" }}>
          Lat {orderStatus.coordinates?.lat}
        </Typography>
        <span>{" - "}</span>
        <Typography variant="caption" style={{ color: "gray" }}>
          Lng {orderStatus.coordinates?.lng}
        </Typography>
      </div>
    </>
  );
};
