import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { useRouteMatch } from "react-router-dom";
import { db } from "../../firebase/config";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  "pk.eyJ1IjoibXl3YWl0YWRhdmlkIiwiYSI6ImNrbW43ZWR4ZzAzeDAycG16ZGR6dTI0MWsifQ.r2NWKe9-sPkaKaEqwK0LEA";
export const Drive = () => {
  // @private
  const dispatch = useDispatch();

  const mapContainer = useRef();
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(12);
  const [gotCoords, setGotCoords] = useState(false);
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const map = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    // map.addControl(directions, "top-left");
    if (gotCoords) {
      const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
      db.collection("orders")
        .doc(match.params.orderId)
        .onSnapshot((doc) => {
          console.log(doc.data());
          let destination = [0, 0];

          if (doc.data().status === "DRIVER_PICKING_UP") {
            destination = [
              doc.data().currentRestaurant.coordinates.lng,
              doc.data().currentRestaurant.coordinates.lat,
            ];
          } else {
            destination = [
              doc.data().coordinates.lng,
              doc.data().coordinates.lat,
            ];
          }
          fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${lng},${lat};${destination[0]},${destination[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data, "data mapbox");
              const geojson = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: data.routes[0].geometry.coordinates,
                },
              };
              if (map?.getSource("route")) {
                map.getSource("route").setData(geojson);
              } else {
                // otherwise, make a new request
                map.addLayer({
                  id: "route",
                  type: "line",
                  source: {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      properties: {},
                      geometry: {
                        type: "LineString",
                        coordinates: geojson.geometry.coordinates,
                      },
                    },
                  },
                  layout: {
                    "line-join": "round",
                    "line-cap": "round",
                  },
                  paint: {
                    "line-color": "#3887be",
                    "line-width": 5,
                    "line-opacity": 0.75,
                  },
                });
              }

              dispatch(setLoading(false));
            });
        });
    }

    return () => map.remove();
  }, [gotCoords, lat, lng]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((data) => {
        console.log(data);
        setLat(data.coords.latitude);
        setLng(data.coords.longitude);
        setGotCoords(true);
      });
    }
  }, []);

  return <div ref={mapContainer} style={{ height: "100vh", width: "100vw" }} />;
};
