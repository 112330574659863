import React, { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  useMediaQuery,
  Paper,
  Typography,
} from "@material-ui/core";
import { numberWithCommas } from "../../utils/numbersWithComma";
export const OrderListing = ({ orders = [] }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    orders.forEach((item) => {
      totalPrice += item.price * item.quantity;
      item.modifiers?.forEach((modifier) => {
        totalPrice += modifier.price;
      });
    });
    setTotal(totalPrice);
  }, [orders]);
  return (
    <div>
      {!isMobile ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit price</TableCell>
              <TableCell>Add-ons</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((item) => {
              let addOnPrice = 0;
              return (
                <TableRow style={{ maxWidth: isMobile ? "100%" : "" }}>
                  <TableCell>
                    <img
                      src={item.image}
                      style={{ maxWidth: isMobile ? "40px" : "150px" }}
                    />
                  </TableCell>
                  <TableCell>
                    {item.productName}
                    {item?.modifiers?.map((modifier) => {
                      return (
                        <p
                          style={{
                            fontSize: "0.7rem",
                            color: "gray",
                            lineHeight: "0.5rem",
                          }}
                        >
                          {modifier.price
                            ? `${modifier.name} + $ ${numberWithCommas(
                                modifier.price
                              )}`
                            : `${modifier.name}`}
                        </p>
                      );
                    })}
                  </TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>$ {numberWithCommas(item.price)}</TableCell>
                  <TableCell>
                    {(() => {
                      item?.modifiers?.forEach((modifier) => {
                        addOnPrice += modifier.price * item.quantity;
                      });

                      return `$ ${numberWithCommas(addOnPrice)}`;
                    })()}
                  </TableCell>
                  <TableCell>
                    $ {numberWithCommas(item.price * item.quantity)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <div>Sub-total : $ {numberWithCommas(total)}</div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: "gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  Tax: $ {numberWithCommas(total * 0.1)}
                </div>
                <div>Total: $ {numberWithCommas(total + total * 0.1)} </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        orders.map((item) => {
          let addOnPrice = 0;
          return (
            <Paper
              style={{
                margin: "1rem 0rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",

                padding: "1rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              ></div>
              <img src={item.image} style={{ maxWidth: "100px" }} />
              <Typography>{item.productName}</Typography>
              <div>
                {item?.modifiers?.map((modifier) => {
                  return (
                    <p
                      style={{
                        fontSize: "0.7rem",
                        color: "gray",
                        lineHeight: "0.5rem",
                      }}
                    >
                      {modifier.price
                        ? `${modifier.name} + $ ${numberWithCommas(
                            modifier.price
                          )}`
                        : `${modifier.name}`}
                    </p>
                  );
                })}
              </div>

              <div>
                $ {numberWithCommas(item.price * item.quantity + addOnPrice)}
              </div>
              <div>x {item.quantity}</div>
            </Paper>
          );
        })
      )}
    </div>
  );
};
