import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import SliderA from "../../../../../assets/images/slider_img_A.jpg";
import SliderB from "../../../../../assets/images/slider_img_B.jpg";
import SliderC from "../../../../../assets/images/slider_img_C.jpg";
import SliderD from "../../../../../assets/images/slider_img_D.jpg";
import SliderE from "../../../../../assets/images/slider_img_E.jpg";
import React, { useEffect } from 'react'

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;

export const HomepageSlider = () => {

  useEffect(() => {
    document.title = `${restaurantName} is an ambitious Deli & Keto Retail Shop.`;
  }, [])

  return (
    <div>
      {enableKiosk === "1" ? (
        <a href="/menu">
          <Carousel fade style={{ marginTop: '0px' }} controls={false}>
            <Carousel.Item>
              <img className="d-block w-100" src={SliderA} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={SliderB} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={SliderC} alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={SliderD} alt="Fourth slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={SliderE} alt="Fifth slide" />
            </Carousel.Item>
          </Carousel>
        </a>
      ) : (
        <Carousel fade>
          <Carousel.Item>
            <img width="100%" height="100%" title="Live | Love | Keto" className="d-block w-100" src={Slider1} alt="Your one-stop shop for quick, healthy, and delicious meals on the go!" loading="lazy" />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1>Live | Love | Keto</h1>
                <p>Your one-stop shop for quick, healthy, and delicious meals on the go!</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" height="100%" title="Live | Love | Keto" className="d-block w-100" src={Slider2} alt="Dine-in | Curbside pickup | In-store shopping" loading="lazy" />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1>Live | Love | Keto</h1>
                <p>Dine-in | Curbside pickup | In-store shopping</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" height="100%" title="Live | Love | Keto" className="d-block w-100" src={Slider3} alt="Stock up your fridge with this keto boost today!" loading="lazy" />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1>Live | Love | Keto</h1>
                <p>Stock up your fridge with this keto boost today!</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" height="100%" title="Live | Love | Keto" className="d-block w-100" src={Slider4} alt="The perfect blend of quality, convenience, and extraordinary taste, delivering piping-hot in every bite, anytime, anywhere." loading="lazy" />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1>Live | Love | Keto</h1>
                <p>The perfect blend of quality, convenience, and extraordinary taste, delivering piping-hot in every bite, anytime, anywhere.</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" height="100%" title="Live | Love | Keto" className="d-block w-100" src={Slider5} alt="Satisfy your cravings while staying true to your low-carb lifestyle." loading="lazy" />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1>Live | Love | Keto</h1>
                <p>Satisfy your cravings while staying true to your low-carb lifestyle.</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      )}
    </div>
  );
};