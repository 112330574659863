import React from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import {
  Text,
  Button,
  Card,
  Container,
  SettingsPanel,
  Toolbox,
  Topbar,
} from "./siteBuilderComponents";
import { Editor, Frame, Element } from "@craftjs/core";

export const WebsiteBuilder = () => {
  return (
    <div style={{ margin: "0 auto", width: "800px" }}>
      <Typography variant="h5" align="center">
        ServingIntel Page builder
      </Typography>
      <Topbar />
      <Editor resolver={{ Card, Button, Text, Container }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Frame>
              <Container padding={5} canvas>
                <Element is={Container} padding={2} canvas></Element>
              </Container>
            </Frame>
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <Toolbox />
              <SettingsPanel />
            </Paper>
          </Grid>
        </Grid>
      </Editor>
    </div>
  );
};
