import "../../../App.css";
import { Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
import siLogo from "../../../assets/images/servingintel-logo-bw.svg";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
export const EloyaltyForgotPW = () => {
  useEffect(() => {
    document.title = "Forgot Password";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };

  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siLogo}
            alt="ServingIntel Logo"
          />
        </Link>
      </Typography>
    );
  }
  const theme = createTheme();

  const [email, setEmail] = useState();
  const history = useHistory();

  const submit = () => {
    if (!email) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
      return false;
    }

    const url =
      "https://ecommv2.servingintel.com/customer/eloyalty-request-reset-password/" +
      serverId +
      "/" +
      siteId +
      "/" +
      email;
    axios
      .get(url, {})
      .then((response) => {
        if (response.data) {
          Swal.fire({
            title: "Password Reset Request.",
            text: "Your password reset verification code has been sent to your email. Copy the code and paste it on the Verification Code field.",
            icon: "success",
            confirmButtonText: "OK",
          });
          history.push("/reset-password");
        } else {
          Swal.fire({
            title: "Password Reset Request Failed!",
            text: "Your are not yet registered. Please create your account.",
            icon: "warning",
            confirmButtonText: "OK",
          });
          history.push("/register");
        }
      })
      .catch((error) => {});
  };

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    if (mail) {
      Swal.fire({
        title: "Email Validation",
        text: "Invalid Email Address '" + mail + "'.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }

    return false;
  };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <Container
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ marginTop: "1rem" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <Typography
              component="h4"
              className="white"
              variant="h4"
              style={{ marginTop: "30px", fontWeight: "550" }}
            >
              Welcome to
            </Typography>
            <img
              className="Logo"
              style={{
                padding: "10px 0",
                width: "400px",
                marginBottom: "1rem",
              }}
              src={Logo}
              alt="AJ's Keto Factory"
            />
            <Paper
              className="formBG"
              style={{
                boxShadow: "none",
                marginTop: "0",
                marginBottom: "1rem",
                background: "beige",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <Typography
                  variant="contained"
                  style={{ margin: "20px auto 0px auto" }}
                >
                  Please enter the email address for your account. A
                  verification code will be sent to you. Once you have received
                  the verification code, you will be able to choose a new
                  password for your account.
                </Typography>
              </Box>
              <Box
                component="form"
                noValidate
                style={{
                  background: "beige",
                  width: "100%",
                  paddingBottom: "1rem",
                }}
              >
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onBlur={(e) => {
                    if (!validateEmail(e.target.value)) {
                      e.target.value = "";
                    }
                  }}
                  autoFocus
                />
              </Box>
            </Paper>
            <Button
              onClick={() => submit()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: "550" }}
            >
              Submit
            </Button>

            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(EloyaltyForgotPW);
